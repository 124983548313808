import React, { Component } from "react";
import "./css/pet-finder.css";

class PetProfile extends Component {
    calculateAge = (birthDate) => {
        const today = new Date(); // Get today's date
        const birthDateObj = new Date(birthDate); // Convert the ISO 8601 string to a Date object

        if (isNaN(birthDateObj)) {
            return "Invalid date"; // Handle invalid date input
        }

        let years = today.getFullYear() - birthDateObj.getFullYear(); // Calculate years
        let months = today.getMonth() - birthDateObj.getMonth(); // Calculate months

        // Adjust if the birthday hasn't occurred yet this year
        if (months < 0 || (months === 0 && today.getDate() < birthDateObj.getDate())) {
            years--;
            months += 12;
        }

        // Adjust the month calculation if today’s date is earlier in the month than the birth date
        if (today.getDate() < birthDateObj.getDate()) {
            months--;
        }

        // Ensure months are within 0-11 range
        months = (months + 12) % 12;

        // Build the output string
        const yearString = years > 0 ? `${years} year${years > 1 ? 's' : ''}` : '';
        const monthString = months > 0 ? `${months} month${months > 1 ? 's' : ''}` : '';

        return `${yearString} ${monthString}`.trim() || "0 month";
    };
    render() {
        const { data } = this.props;
        return (
            <div className="pet-profile">
                <div className="pet-profile-header">
                    <div className="image-section">
                        <img
                            src={data.image != null ? `https://animoxkart-users.s3.ap-south-1.amazonaws.com/pets/${data.image}` : `${process.env.PUBLIC_URL}/assets/images/pets/smart-pet/${data.pet_type}.png`}
                            // src={data.image || "default-dog-image.jpg"} // Use data.image or fallback to a default image
                            alt={data.name || "Pet"}
                            className="dog-image"
                        />
                    </div>
                    <div className="profile-name-section">
                        <div className="name-section">
                            <h2>{data.pet_name || "My Pet"}</h2>
                            <p>{`${data.breed || "Breed"} · ${this.calculateAge(data.dob)}`}</p>
                        </div>
                    </div>
                </div>
                <div className="section-2-area">
                    <div className="about-section">
                        <h3>About {data.pet_name || "the Pet"}</h3>
                        <div className="stats">
                            <div className="stat">
                                <p>Weight</p>
                                <h4>{data.weight || "N/A"} kg</h4>
                            </div>
                            <div className="stat">
                                <p>Gender</p>
                                <h4>{data.gender || "N/A"}</h4>
                            </div>
                            <div className="stat">
                                <p>Colour</p>
                                <h4>{data.colour || "N/A"}</h4>
                            </div>
                        </div>
                    </div>
                    <div className="location-section">
                        <button className="location-button btn" onClick={this.props.onLocationClick}>Get pet location</button>
                    </div>
                    <div className="behaviour-section">
                        <h3><i class="fa-solid fa-paw"></i> Behaviour</h3>
                        <div className="capsule-area">
                            {
                                data.behaviour ? data.behaviour.map((behaviour, index) => (
                                    <span key={index} className="capsule">{behaviour}</span>
                                )) : ''
                            }
                        </div>
                    </div>
                    <div className="health-section">
                        <h3><i class="fa-solid fa-heart"></i> Health</h3>
                        <div className="capsule-area">
                            {
                                data.health ? data.health.map((health, index) => (
                                    <span key={index} className="capsule">{health}</span>
                                )) : ''
                            }
                        </div>
                    </div>
                    <div className="food-section">
                        <h3><i class="fa-solid fa-bone"></i> Food</h3>
                        <div className="capsule-area">
                            {
                                data.food ? data.food.map((food, index) => (
                                    <span key={index} className="capsule">{food}</span>
                                )) : ''
                            }
                        </div>
                    </div>
                </div>

                <div className="family-section">
                    <h3><i class="fa-solid fa-users"></i> Family's</h3>
                    {/* <div className="m-2" >
                        <button className="btn" onClick={this.openChat} style={{width:"100%"}}>
                            <img className="contact-icon" src={`${process.env.PUBLIC_URL}/assets/images/icon/message.png`} alt="message" />
                            <span> Start a Secure Chat.</span>
                        </button>
                    </div> */}
                    <div className="family-area">
                        {
                            data.owners ? data.owners.map((family, index) => (
                                <div key={index} className="family-member">
                                    <hr />
                                    <div className="header">
                                        <div>
                                            <img className="avatar" src={`${process.env.PUBLIC_URL}/assets/images/icon/user.png`} alt="avatar" />
                                        </div>
                                        <div className="detail">
                                            <h4>{family.owner_name}</h4>
                                            <div className="contact-info">
                                                {
                                                    data.alert_on ? family.show_contact && <p>{family.contact_no} </p> : ''
                                                }
                                            </div>
                                        </div>
                                        <div className="contact-icon-area">
                                            {family.show_contact ? <a href={`tel:+91${family.contact_no}`} >
                                                <img className="contact-icon" src={`${process.env.PUBLIC_URL}/assets/images/icon/telephone.png`} alt="telephone" />
                                            </a> : ''}
                                            {family.show_whatsapp ? <a
                                                href={`https://wa.me/+91${family.whatsapp_no}?text=Hello%20I%20found%20your%20${encodeURIComponent(data.pet_type)}%20${encodeURIComponent(data.pet_name)}`}
                                                className="icon"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <img className="contact-icon" src={`${process.env.PUBLIC_URL}/assets/images/icon/whatsapp.png`} alt="whatsapp" />
                                            </a> : ''}
                                        </div>
                                    </div>
                                    {family.show_address == 1 ? <div className="address">
                                        <p>
                                            <span style={{ color: "black" }}>Address:</span><br />
                                            {family.address.area}, {family.address.city}, {family.address.state}, {family.address.country}.
                                            <br />
                                            <span>Pincode: {family.address.pincode}</span>
                                        </p>
                                    </div> : ""}
                                    

                                </div>
                            )) : ''
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default PetProfile;
