import React, { Component } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import Pace from 'react-pace-progress'
import {BlinkBlur} from 'react-loading-indicators'
import {isLoggedin, getContactIdFromJWT} from '../common/utils/index';
import FinderTagIntro from './finder-tag-intro';
class PetFinder extends Component {

    constructor(props) {
        super(props)
        isLoggedin('pet-finder-tag/', true);

        const contact_id = getContactIdFromJWT();
        const data = {
            "contact_id": contact_id
        }
        this.state = {
            tags: [],
            loading: true,
        }
        this.load(data.contact_id);

    }
    getTags = async (contact_id) => {
        const response = await axios.get(process.env.REACT_APP_API_URL_NEW + '/tags-by-contact/' + contact_id, {
            headers: {
                "Content-Type": "application/json",
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer ' + Cookies.get('token'),
            }
        });
        if (response.data != null) {
            this.setState({
                tags: response.data.tags,
            })
        }
    }
    calculateAge = (birthDate) => {
        const today = new Date(); // Get today's date
        const birthDateObj = new Date(birthDate); // Convert the birthDate string to a Date object

        let age = today.getFullYear() - birthDateObj.getFullYear(); // Calculate the age by subtracting years

        // Check if the current date has passed the birthday this year
        const monthDiff = today.getMonth() - birthDateObj.getMonth();
        const dayDiff = today.getDate() - birthDateObj.getDate();

        // If birthday hasn't occurred yet this year, subtract 1 from the age
        if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
            age--;
        }

        return age;
    }
    capitalizeFirstLetter = (str) => {
        if (str.length === 0) return str; // Handle empty string case
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    load = async (contact_id) => {
        try {
            // Wait for getTags to complete before proceeding
            await this.getTags(contact_id);
        } catch (error) {
            console.error("Error loading tags:", error);
        } finally {
            this.setState({ loading: false });
        }
    };

    render() {


        return (
            <div>
                {/* <Breadcrumb title={'Pet Finder'}/> */}


                {/*Collection section*/}
                <section className="collection section-b-space">
                    {/* <FinderTagIntro /> */}
                    <div className="container">
                    {this.state.loading? 
                        <div className="loading-indicator"><BlinkBlur color="#427fc1" size="small" text="loading" textColor="#020202" /></div> :
                        <div className="row partition-collection">
                                {this.state.tags.length > 0 ?
                                    this.state.tags.map((details, index) =>
                                        <div key={index} className="col-lg-3 col-md-6" >
                                            <div className="collection-block" style={{ border: "0.5px solid #f2f2f2" }}>
                                                {details.image ? <img src={`https://animoxkart-users.s3.ap-south-1.amazonaws.com/pets/${details.image}`} className="img-fluid" alt="" style={{ maxHeight: 250, maxWidth: 250 }} /> : <img src={`${process.env.PUBLIC_URL}/assets/images/pets/smart-pet/${details.pet_type}.png`} className="img-fluid" alt="pet" />}
                                                <div className="collection-content">
                                                    <h4>({details.pet_type})</h4>
                                                    <h3>{details.pet_name}</h3>
                                                    <p>{this.capitalizeFirstLetter(details.gender)}, Age: {this.calculateAge(details.dob)} Yr.</p>
                                                    <a href={`pet-finder-tag/view/${details.tag_id}`} className="m-2 btn btn-outline">View</a>
                                                    <a href={`pet-finder-tag/update/${details.tag_id}`} className="m-2 btn btn-outline">Edit</a>
                                                </div>
                                            </div>
                                        </div>
                                    ) :
                                    <div className="col-sm-12 empty-cart-cls text-center">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`} className="img-fluid mb-4" alt="" />
                                        <h3><strong>No Finder Tag Found</strong></h3>
                                        <h4>Register the finder tag. Scan the locket QR code, and register your pet.</h4>
                                    </div>
                            }
                        
                            </div>
                    }
                        
                    </div>
                </section>

            </div>
        )
    }
}

export default PetFinder