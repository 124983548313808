import React, { Component } from "react";
import "./css/pet-finder.css";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
class FinderTagIntro extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showShareLocationButton: true,
            showAlert: false,
        };
    }

    componentDidMount = () => {
        if (!navigator.geolocation) {
            this.setState({
                showShareLocationButton: false
            })
        }
    }
    notify = () => toast('Thank You! Location shared.');
    calculateAge = (birthDate) => {
        const today = new Date(); // Get today's date
        const birthDateObj = new Date(birthDate); // Convert the ISO 8601 string to a Date object

        if (isNaN(birthDateObj)) {
            return "Invalid date"; // Handle invalid date input
        }

        let years = today.getFullYear() - birthDateObj.getFullYear(); // Calculate years
        let months = today.getMonth() - birthDateObj.getMonth(); // Calculate months

        // Adjust if the birthday hasn't occurred yet this year
        if (months < 0 || (months === 0 && today.getDate() < birthDateObj.getDate())) {
            years--;
            months += 12;
        }

        // Adjust the month calculation if today’s date is earlier in the month than the birth date
        if (today.getDate() < birthDateObj.getDate()) {
            months--;
        }

        // Ensure months are within 0-11 range
        months = (months + 12) % 12;

        // Build the output string
        const yearString = years > 0 ? `${years} yr${years > 1 ? 's' : ''}` : '';
        const monthString = months > 0 ? `${months} mo${months > 1 ? 's' : ''}` : '';

        return `${yearString} ${monthString}`.trim() || "0 mo";
    };
    shareCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    const currentTime = new Date().toISOString();
                    const newLocation = {
                        "location": {
                            latitude: parseFloat(latitude.toFixed(6)),
                            longitude: parseFloat(longitude.toFixed(6)),
                            timestamp: currentTime,
                        }
                    };

                    this.saveLocation(newLocation);
                },
                (error) => {
                    alert("Unable to retrieve location. Please enable location services.");
                }
            );
        }
    };
    saveLocation = async (newLocation) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL_NEW}/tags/${this.props.data.tag_id}/save-location`, newLocation, {
                headers: {
                    "Content-Type": "application/json",
                    'Access-Control-Allow-Origin': '*',
                },
            });

            if (response.status === 200) {
                this.notify();
            } else {
                console.error('Failed to submit data');
            }
        } catch (error) {
            console.error('Error submitting data:', error);
        }
    }
    openChat = () => {
        window.location.href = "/chat";
    };

    render() {
        const { data } = this.props;
        return (
            <div>
                <section>
                    <div>
                        <div className="first-section" style={{ backgroundImage: "url('https://animoxkart-products.s3.ap-south-1.amazonaws.com/home/46.jpg')" }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        <div>
                                            <h2>Smart, Secure, and Stylish – The Ultimate Pet Tag!</h2>
                                            <h4>Next-Level Pet Safety at Your Fingertips!</h4>
                                            <a href="shop/regular" className="btn btn-solid">buy now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div>
                        <div className="first-section" style={{ backgroundImage: "url('https://animoxkart-products.s3.ap-south-1.amazonaws.com/home/46.jpg')" }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        <div>
                                            <h2>Did you know?
                                                Over 10 million pets go missing every year, with only 16-20% reunited.</h2>
                                            <h4>Smart Pet Finder Tag – Always connected, Always protected.!</h4>
                                            {/* <a href="shop/regular" className="btn btn-solid">buy now</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div>
                        <div className="first-section" style={{ backgroundImage: "url('https://animoxkart-products.s3.ap-south-1.amazonaws.com/home/46.jpg')" }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        <div>
                                            <h2>Did you know?
                                                Over 10 million pets go missing every year, with only 16-20% reunited.</h2>
                                            <h4>Smart Pet Finder Tag – Always connected, Always protected.!</h4>
                                            {/* <a href="shop/regular" className="btn btn-solid">buy now</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="how-it-works">
                    <h2 class="title">How does it work?</h2>
                    <div class="content">
                        <div class="text-section">
                            <h3 class="pet-sub-title">
                                <span class="icon">📸</span> Easy Scan, Safe Return
                            </h3>
                            <p class="description">
                                Each <span class="highlight">furTag</span> has a visible QR code that's quick to scan (no app needed). One scan shows your pet’s profile, with contacts, medical info, and behavior notes, helping anyone bring them home faster.
                            </p>
                        </div>
                        <div class="image-section">
                            <img src="your-image-path.jpg" alt="Scanning QR Code on furTag" />
                        </div>
                    </div>
                    <div class="content">
                        <div class="text-section">
                            <h3 class="pet-sub-title">
                                <span class="icon">📸</span> Easy Scan, Safe Return
                            </h3>
                            <p class="description">
                                Each <span class="highlight">furTag</span> has a visible QR code that's quick to scan (no app needed). One scan shows your pet’s profile, with contacts, medical info, and behavior notes, helping anyone bring them home faster.
                            </p>
                        </div>
                        <div class="image-section">
                            <img src="your-image-path.jpg" alt="Scanning QR Code on furTag" />
                        </div>
                    </div>
                    <div class="content">
                        <div class="text-section">
                            <h3 class="pet-sub-title">
                                <span class="icon">📸</span> Easy Scan, Safe Return
                            </h3>
                            <p class="description">
                                Each <span class="highlight">furTag</span> has a visible QR code that's quick to scan (no app needed). One scan shows your pet’s profile, with contacts, medical info, and behavior notes, helping anyone bring them home faster.
                            </p>
                        </div>
                        <div class="image-section">
                            <img src="your-image-path.jpg" alt="Scanning QR Code on furTag" />
                        </div>
                    </div>
                </section>
                <section class="furtag-advantage">
                    <h2 class="title">the furtag advantage</h2>
                    <p class="subtitle">
                        Peace of mind with no charging, no app, and instant GPS location tracking—all in one simple, durable tag.
                    </p>
                    <div class="features">
                        <div class="feature-item">
                            <div class="icon">⭐</div>
                            <p>No Battery, No Charging, No Radiation, No Rashes</p>
                        </div>
                        <div class="feature-item">
                            <div class="icon">📍</div>
                            <p>Accurate Real-Time GPS location upon scanning</p>
                        </div>
                        <div class="feature-item">
                            <div class="icon">📖</div>
                            <p>Customizable Pet Profile with Multiple Contacts & Addresses Info</p>
                        </div>
                        <div class="feature-item">
                            <div class="icon">✔️</div>
                            <p>Durable Design comes with Lifetime Guarantee & Free Replacements!</p>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="customer-review">
                        <div className="row">
                            <div class="card" style={{ width: "18rem" }}>
                                <img src="..." class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title">Card title</h5>
                                    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#" class="btn btn-primary">Go somewhere</a>
                                </div>
                            </div>
                            <div class="card" style={{ width: "18rem" }}>
                                <img src="..." class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title">Card title</h5>
                                    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#" class="btn btn-primary">Go somewhere</a>
                                </div>
                            </div>
                            <div class="card" style={{ width: "18rem" }}>
                                <img src="..." class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title">Card title</h5>
                                    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#" class="btn btn-primary">Go somewhere</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="faq-section container my-5">
                    <h1 class="text-center mb-4">FAQs</h1>
                    <hr class="mx-auto" style={{ width: "50px", height: "2px", backgroundColor: "black" }} />
                    <div class="accordion accordion-flush" id="accordionFlushExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingOne">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    Accordion Item #1
                                </button>
                            </h2>
                            <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the first item's accordion body.</div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingTwo">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                    Accordion Item #2
                                </button>
                            </h2>
                            <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the second item's accordion body. Let's imagine this being filled with some actual content.</div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingThree">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                    Accordion Item #3
                                </button>
                            </h2>
                            <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default FinderTagIntro;
